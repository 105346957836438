.container
{
    display: flex;
    margin: 4rem 0 2rem 0;
}

.cardsWrapper
{
    display: flex;
    justify-content: center;
    margin: 0 2rem;
    width: 100%;
}

.card
{
    width: 25%;
    padding-bottom: 2rem;
    border-radius: 20px;
    margin: 0 1.5rem;
}

.title
{
    width: fit-content;
    height: fit-content;
    padding: 1rem 2rem;
    border-radius: 50px;
    font-size: 1rem;
    transform: rotate(-5deg);
    margin-top: -2rem;
    margin-left: -1rem;
    color: #786A50;
}

.card .image
{
    display: flex;
    justify-content: center;
}

.card img
{
    height: 5rem;
    margin-bottom: 2rem;
}

.card h3
{
    font-size: 1rem;
    text-align: center;
    padding: 0 1rem;
    margin-bottom: 2rem;
}

.buttonWrapper
{
    width: fit-content;
    margin: 0 auto;
    background-color: #F8EEBB;
    padding: 1.5rem;
    border-radius: 50px;
    font-size: 1rem;
    font-family: 'FOT-Seurat Pro B';
}

.buttonWrapper a
{
    color: #786A50;
    position: relative;
    z-index: 2;
}

.hover
{
    width: 75%;
    height: 10px;
    margin: -0.75rem auto 0 auto;
    border-radius: 20px;
    background-color: #F7D359;
    opacity: 0;
    position: relative;
    z-index: 1;
}

.buttonWrapper:hover
{
    cursor: pointer;
}

.buttonWrapper:hover > .hover
{
    opacity: 1;
}

#time
{
    background-color: #FEF8FF;
}

#timeTitle
{
    background-color: #F2D7F6;
}

#project
{
    background-color: #F2FFF7;
}

#projectTitle
{
    background-color: #CCF4DB;
}

#api
{
    background-color: #F6FEFF;
}

#apiTitle
{
    background-color: #BDE9F2;
}

#api a
{
    color: #2AA1F1;
}

#api a:hover
{
    text-decoration: underline;
}

#apiText
{
    margin-top: -1.75rem;
}