.container
{
    height: fit-content;
    width: 100%;
    background-image: url('../../assets/Background_NookLeaf.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    padding: 2rem 0 4rem 0;
    margin-bottom: 2rem;
}

.container h1
{
    text-align: center;
    font-size: 1.25rem;
}

.buttonsWrapper
{
    display: flex;
    justify-content: center;
    width: 30%;
    margin: 0 auto;
}

.buttonWrapper
{
    width: fit-content;
    margin: 0 auto;
    background-color: #F8EEBB;
    padding: 1.5rem;
    border-radius: 50px;
    font-size: 1rem;
    font-family: 'FOT-Seurat Pro B';
}

.buttonWrapper a
{
    color: #786A50;
    position: relative;
    z-index: 2;
}

.hover
{
    width: 75%;
    height: 10px;
    margin: -0.75rem auto 0 auto;
    border-radius: 20px;
    background-color: #F7D359;
    opacity: 0;
    position: relative;
    z-index: 1;
}

.buttonWrapper:hover
{
    cursor: pointer;
}

.buttonWrapper:hover > .hover
{
    opacity: 1;
}