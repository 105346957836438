html, body
{
  font-family: 'FOT-Seurat Pro', arial;
  color: #393223;
  margin: 0;
  padding: 0;
  height: 101%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a
{
  text-decoration: none;
  color: inherit;
}