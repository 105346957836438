.container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.container h1
{
    text-align: center;
}

.container h3
{
    text-align: center;
    
}

#tagline
{
    margin-top: -1.5rem;
}

.cardsWrapper
{
    display: flex;
    justify-content: center;
    margin: 2rem 0 2rem 0;
}

.card
{
    width: 20%;
    padding-bottom: 2rem;
    border-radius: 20px;
    margin: 0 1rem;
}

.title
{
    width: fit-content;
    height: fit-content;
    padding: 1rem 2rem;
    border-radius: 50px;
    font-size: 1rem;
    transform: rotate(-5deg);
    margin-top: -2rem;
    margin-left: -1rem;
    color: #786A50;
}

.card .image
{
    display: flex;
    justify-content: center;
}

.card img
{
    height: 5rem;
    margin-bottom: 2rem;
}

#sea
{
    background-color: #FEF8FF;
}

#seaTitle
{
    background-color: #F2D7F6;
}

#fish
{
    background-color: #F6FEFF;
}

#fishTitle
{
    background-color: #BDE9F2;
}

#bug
{
    background-color: #F2FFF7;
}

#bugTitle
{
    background-color: #CCF4DB;
}

#fossil
{
    background-color: #F3F6FF;
}

#fossilTitle
{
    background-color: #D0D9F9;
}

.section
{
    width: 50%;
    margin: 0 auto 1rem auto;
    display: flex;
    flex-direction: column;
    transition: 0.3s all ease;
}

.header
{
    height: 50px;
    width: 100%;
    border-radius: 20px;
    margin: 0 auto;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.3s all ease;
}

.header:hover
{
    cursor: pointer;
}

.header h1
{
    font-size: 1rem;
    margin-left: 1rem;
}

.searchWrapper
{
    display: flex;
}

input
{
    border: none;
    background-color: #F8EEBB;
    border-radius: 50px;
    padding: 0.5rem 1rem;
}

::placeholder
{
    color: #786A50;
}

.image
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconify
{
    height: 1.5rem;
    margin-right: 1rem;
    color: #786A50;
}

.iconify:hover
{
    cursor: pointer;
}

.row
{
    display: flex;
    justify-content: space-between;
    margin-left: 1rem;
}

#names
{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
}

#names h1
{
    color: #786A50;
    font-size: 0.85rem;
}

.sea
{
    background-color: #FEF8FF;
}

.seaArrow
{
    stroke: #F2D7F6;
    transform: rotate(180deg);
    margin-right: 1rem;
}

.fish
{
    background-color: #F6FEFF;
}

.fishArrow
{
    stroke: #BDE9F2;
    transform: rotate(180deg);
    margin-right: 1rem;
}

.bug
{
    background-color: #F2FFF7;
}

.bugArrow
{
    stroke: #CCF4DB;
    transform: rotate(180deg);
    margin-right: 1rem;
}

.fossil
{
    background-color: #F3F6FF;
}

.fossilArrow
{
    stroke: #D0D9F9;
    transform: rotate(180deg);
    margin-right: 1rem;
}

.open .arrow
{
    transform: rotate(0deg);
}

.open .sea
{
    background-color: #FCECFF;
}

.open .fish
{
    background-color: #E2FCFF;
}

.open .bug
{
    background-color: #E2FFED;
}

.open .fossil
{
    background-color: #E7EDFF;
}

.hide
{
    display: none;
    transition: 0.3s all ease;
}