.loadingContainer
{
    width: 100vh;
    height: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    overflow: visible;
}

.container h1
{
    text-align: center;
}

.container h3
{
    text-align: center;
}

#tagline
{
    margin-top: -1.5rem;
}

.cardsWrapper
{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 2rem 0;
}

.card
{
    width: 20%;
    padding-bottom: 2rem;
    border-radius: 20px;
    margin: 0 1.5rem;
}

.title
{
    width: fit-content;
    height: fit-content;
    padding: 1rem 2rem;
    border-radius: 50px;
    font-size: 1rem;
    transform: rotate(-5deg);
    margin-top: -2rem;
    margin-left: -1rem;
    color: #786A50;
}

.card .image
{
    display: flex;
    justify-content: center;
}

.card img
{
    height: 5rem;
    margin-bottom: 2rem;
}

.section
{
    width: 50%;
    margin: 0 auto 1rem auto;
    display: flex;
    flex-direction: column;
    transition: 0.3s all ease;
}

.header
{
    height: 50px;
    width: 100%;
    background-color: #FFF9E6;
    border-radius: 20px;
    margin: 0 auto;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.3s all ease;
}

.header h1
{
    font-size: 1rem;
    margin-left: 2rem;
}

.searchWrapper
{
    display: flex;
    align-items: center;
}

.searchWrapper input
{
    border: none;
    background-color: #F8EEBB;
    border-radius: 50px;
    height: fit-content;
    padding: 0.5rem 1rem;
}

#sea .searchWrapper input[type=text]:focus
{
    outline: 2px solid #F0BBF8;
}

#fish .searchWrapper input[type=text]:focus
{
    outline: 2px solid #A1DAE5;
}

#bug .searchWrapper input[type=text]:focus
{
    outline: 2px solid #A2E7BC;
}

#fossil .searchWrapper input[type=text]:focus
{
    outline: 2px solid #A2B2EB;
}

.searchWrapper ::placeholder
{
    color: #786A50;
}

.image
{
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconify
{
    height: 1.5rem;
    color: #786A50;
    background-color: #F8EEBB;
    border-radius: 50px;
    padding: 0.35rem 0.1rem;
    margin: 0 1rem;
}

.iconify:hover
{
    cursor: pointer;
}

.searchWrapper h2
{
    color: #F5C24C;
    margin-right: 1rem;
    font-family: 'FOT-Seurat Pro B';
}

.searchWrapper h2:hover
{
    cursor: pointer;
}

.row
{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1rem;
    align-items: center;
    margin-left: 1rem;
}

#fossil .row, #fossil #names
{
    grid-template-columns: repeat(4, 1fr);
}

.row h1
{
    font-size: 1rem;
    text-align: left;
}

.row img
{
    height: 3rem;
    background-color: var(--color);
    padding: 1rem;
    margin-left: -1rem;
    border-radius: 50%;
}

#names h1
{
    color: #786A50;
    font-size: 0.85rem;
}

#names h1:hover
{
    cursor: pointer;
}

.fossil
{
    grid-template-columns: repeat(4, 1fr);
}

.filterWrapper
{
    background-color: #FFF9E6;
    color: #393223;
    padding: 0 1rem;
    border-radius: 50px;
    margin-top: -0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3rem;
}

.filter
{
    display: flex;
    align-items: center;
    height: fit-content;
}

.filterItem
{
    display: flex;
    align-items: center;
    width: fit-content;
    margin-right: 1rem;
    border-radius: 50px;
    padding: 0rem 0.5rem;
    height: 2.25rem;
}

.filterItem h2
{
    width: fit-content;
    font-size: 0.85rem;
    color: #393223;
    font-family: 'FOT-Seurat Pro B';
}

.arrow
{
    transform: rotate(180deg);
    height: 0.65rem;
    margin-left: 0.25rem;
}

.filterItem:hover
{
    cursor: pointer;
}

.clear h2
{
    width: fit-content;
    text-align: right;
    color: #786A50;
    font-size: 0.85rem;
    font-family: 'FOT-Seurat Pro B';
}

.clear h2:hover
{
    cursor: pointer;
}

.hide
{

    transition: 0.3s all ease;
}

.card:hover
{
    cursor: pointer;
}

.resultsWrapper
{
    width: 100%;
    height: 60vh;
    overflow-y: scroll;
}

#loader
{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75.75vh;
}

#loader img
{
    max-height: 7rem;
    max-width: 7rem;
    height: auto;
    width: auto;
}

#sea .header, #sea .filterWrapper
{
    background-color: #FEF8FF;
}

#sea .searchBar, #sea .iconify
{
    background-color: #F2D7F6;
}

#sea h2
{
    color: #F0BBF8;
}

#fish .header, #fish .filterWrapper
{
    background-color: #F6FEFF;
}

#fish .searchBar, #fish .iconify
{
    background-color: #BDE9F2;
}

#fish h2
{
    color: #A1DAE5;
}

#bug .header, #bug .filterWrapper
{
    background-color: #F2FFF7;
}

#bug .searchBar, #bug .iconify
{
    background-color: #CCF4DB;
}

#bug h2
{
    color: #A2E7BC;
}

#fossil .header, #fossil .filterWrapper
{
    background-color: #F3F5FF;
}

#fossil .searchBar, #fossil .iconify
{
    background-color: #D0D9F9;
}

#fossil h2
{
    color: #A2B2EB;
}