.container
{
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 60%;
}

.text
{
    font-size: 0.85rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.text h1
{
    margin-bottom: 2rem;
}

.buttonWrapper
{
    width: fit-content;
}

.buttonWrapper
{
    background-color: #F8EEBB;
    padding: 1.5rem;
    border-radius: 50px;
    margin-top: -1rem;
}

.buttonWrapper h1
{
    color: #786A50;
    font-size: 1.25rem;
    z-index: 1;
    margin: -0.75rem 0 1.5rem 0;
    padding: 0;
    position: relative;
}

.hover
{
    width: 85%;
    height: 10px;
    margin: -2.4rem auto -0.5rem auto;
    border-radius: 20px;
    background-color: #F7D359;
    z-index: 0;
    position: relative;
    opacity: 0;
}

.buttonWrapper:hover
{
    cursor: pointer;
}

.buttonWrapper:hover > .hover
{
    opacity: 1;
}

.imageWrapper
{
    display: flex;
    flex-direction: column;
}

#nooksCranny
{
    height: 20rem;
}

.credit
{
    font-size: 1rem;
    color: #A89E8C;
    text-align: center;
    margin-top: -1.25rem;
    transform: translateY(-100px);
    opacity: 0;
    transition: 0.3s all ease;
    z-index: -1;
}

#nooksCranny:hover
{
    cursor: pointer;
}

#nooksCranny:hover ~ .credit
{
    transform: translateY(0px);
    opacity: 1;
}